import React, { useRef, useState, useEffect } from 'react'
import cn from 'classnames'
import * as st from '~/assets/styl/Accordion.module.styl'

interface Props {
  title: string
  children: React.ReactNode
  open: boolean
  onOpen: () => void
}

const Accordion = ({ title, children, open, onOpen }: Props) => {
  const content = useRef<HTMLDivElement>()
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (open) {
      setHeight(content.current.offsetHeight)
      setTimeout(() => setHeight(-1), 200)
    } else {
      setHeight(content.current.offsetHeight)
      setTimeout(() => setHeight(0), 100)
    }
  }, [open])

  return (
    <div className={cn(st.core, open && st.open)} onClick={onOpen}>
      <h3 className={st.heading}>
        <span>{title}</span>
        <span className="icon-arrow-accordion"></span>
      </h3>
      <div
        className={st.content}
        style={{ maxHeight: height >= 0 ? `${height}px` : 'none' }}
      >
        <div ref={content}>{children}</div>
      </div>
    </div>
  )
}

export default Accordion
