import React from 'react'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import { Link, Logo } from '~/components'
import { navItems } from '~/data'
import cn from 'classnames'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as st from '~/assets/styl/Footer.module.styl'
import * as st1 from '~/assets/styl/MenuBar.module.styl'

const Footer = () => {
  const query = useStaticQuery(graphql`
    query {
      allConfigurations {
        nodes {
          facebook
          instagram
          linkedin
          whatsapp
          phone
          youtube
        }
      }
    }
  `)

  const {
    configuration: config,
  }: {
    configuration: {
      facebook?: string
      instagram?: string
      linkedin?: string
      youtube?: string
      whatsapp: string
      phone: string
    }
  } = useApi(
    {
      configuration: query.allConfigurations.nodes[0],
    },
    'configuration'
  )

  const breakpoints = useBreakpoint()
  const desktop = breakpoints.md

  return (
    <footer className={st.core}>
      <div className={st.container}>
        <div className={st.top}>
          <Link href="/">
            <Logo />
          </Link>
          <div
            className={cn(st1.nav, st.nav)}
            itemScope
            itemType="http://schema.org/SiteNavigationElement"
          >
            <ul>
              {navItems.map(({ name, url }, key) => (
                <li key={key}>
                  <Link href={url}>{name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <ul className={st.socials}>
            {config.instagram && (
              <li>
                <Link
                  href={config.instagram}
                  external
                  className="icon-instagram"
                ></Link>
              </li>
            )}
            {config.facebook && (
              <li>
                <Link
                  href={config.facebook}
                  external
                  className="icon-facebook"
                ></Link>
              </li>
            )}
            {config.linkedin && (
              <li>
                <Link
                  href={config.linkedin}
                  external
                  className="icon-linkedin"
                ></Link>
              </li>
            )}
            {config.youtube && (
              <li>
                <Link
                  href={config.youtube}
                  external
                  className="icon-youtube"
                ></Link>
              </li>
            )}
          </ul>
        </div>
        <div className={st.bottom}>
          <div>
            <p className={st.location}>
              R. José Aurino de Matos, 195{desktop ? ' - ' : <br />}
              Kobrasol, São José - SC, 88102-450
            </p>
            <ul className={st.phones}>
              {config.phone && (
                <Link href={`tel:${config.phone.replace(/\D/g, '')}`} external>
                  <span className="icon-phone"></span>
                  {config.phone}
                </Link>
              )}
              {config.whatsapp && (
                <Link
                  href={`https://api.whatsapp.com/send?phone=55${config.whatsapp.replace(
                    /\D/g,
                    ''
                  )}`}
                  external
                >
                  <span className="icon-whatsapp"></span>
                  {config.whatsapp}
                </Link>
              )}
            </ul>
          </div>
          <div className={st.copy}>
            {desktop && (
              <span>
                © 2022 Votis Construtora. Todos os direitos reservados.
              </span>
            )}
            <Link href="/politica-de-privacidade/">
              Política de Privacidade
            </Link>
            <Link href="/termos-de-uso/">Termos de uso</Link>
            {!desktop && (
              <span>
                © 2022 Votis Construtora.
                <br />
                Todos os direitos reservados.
              </span>
            )}
          </div>
        </div>
        <div className={st.tiki}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://www.tiki.com.br" target="_blank">
            Tiki
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
