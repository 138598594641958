exports.components = {
  "component---src-templates-about-tsx": () => import("./../../../src/templates/About.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-enterprise-tsx": () => import("./../../../src/templates/Enterprise.tsx" /* webpackChunkName: "component---src-templates-enterprise-tsx" */),
  "component---src-templates-enterprises-tsx": () => import("./../../../src/templates/Enterprises.tsx" /* webpackChunkName: "component---src-templates-enterprises-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-not-found-tsx": () => import("./../../../src/templates/NotFound.tsx" /* webpackChunkName: "component---src-templates-not-found-tsx" */),
  "component---src-templates-privacy-policy-tsx": () => import("./../../../src/templates/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-tsx" */),
  "component---src-templates-sales-tsx": () => import("./../../../src/templates/Sales.tsx" /* webpackChunkName: "component---src-templates-sales-tsx" */),
  "component---src-templates-success-tsx": () => import("./../../../src/templates/Success.tsx" /* webpackChunkName: "component---src-templates-success-tsx" */)
}

