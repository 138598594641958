/* eslint-disable react/prop-types */
import React from 'react'
import styled, { css } from 'styled-components'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import { Loading } from '~/components'

const Images = ['div', 'li'].reduce((obj, key) => {
  obj[key] = styled[key]`
    ${(props) =>
      props.position &&
      css`
        position: ${props.position};
      `}
    ${(props) =>
      props.src && props.contain
        ? css`
            background: url('${props.src}') no-repeat center / contain;
          `
        : css`
            background: url('${props.src}') center / cover;
          `}
  `
  return obj
}, {})

const Lazyload = ({
  src,
  children,
  type = 'div',
  position = 'relative',
  ...props
}) => {
  const Image = Images[type]

  return (
    <LazyImageFull src={src}>
      {({ imageState, ref }) =>
        type === 'img' ? (
          <img
            ref={ref}
            src={imageState === ImageState.LoadSuccess ? src : null}
            {...props}
          />
        ) : (
          <Image
            ref={ref}
            src={imageState === ImageState.LoadSuccess ? src : null}
            position={position}
            {...props}
          >
            {imageState !== ImageState.LoadSuccess ? (
              <Loading active absolute />
            ) : (
              children
            )}
          </Image>
        )
      }
    </LazyImageFull>
  )
}

export default Lazyload
