import React from 'react'
import { Link } from '~/components'
import { GatsbyLinkProps } from 'gatsby'
import cn from 'classnames'
import * as st from '~/assets/styl/Button.module.styl'

type Props = Omit<
  Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>,
  'to'
> & {
  href?: string
  onClick?: React.MouseEventHandler
  external?: boolean
  domainPrefixed?: boolean
}

const Button = ({ href, onClick, children, className, ...props }: Props) =>
  href ? (
    <Link
      href={href}
      onClick={onClick}
      className={cn(st.core, className)}
      {...props}
    >
      <span>{children}</span>
    </Link>
  ) : (
    <button onClick={onClick} className={cn(st.core, className)}>
      <span>{children}</span>
    </button>
  )

export default Button
