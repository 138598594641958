import React from 'react'
import { scroller } from 'react-scroll'
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby'

type Props = Omit<
  Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>,
  'to'
> & {
  external?: boolean
  href: string
  domainPrefixed?: boolean
}

const Link = ({ external, href, onClick, domainPrefixed, ...props }: Props) => {
  if (external)
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
        {...props}
      ></a>
    )
  if (domainPrefixed) href = href.replace(/https?:\/\/(.*?)\//, '/')
  return (
    <GatsbyLink
      to={href}
      onClick={(e) => {
        if (href.includes('#'))
          scroller.scrollTo(href.split('#')[1], {
            smooth: true,
            duration: 500,
            offset: -0.65 * document.getElementById('menu-bar').offsetHeight,
          })
        onClick?.(e)
      }}
      {...props}
    ></GatsbyLink>
  )
}

export default Link
