import React, { useState } from 'react'
import { Lazyload } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/Values.module.styl'
import * as st1 from '~/assets/styl/Hero.module.styl'

interface Props {
  noPadding: boolean
  items: Array<{
    id: number
    name: string
    description: string
    image: string
  }>
}

export default function Values({ items, noPadding }: Props) {
  const [active, setActive] = useState(0)

  return (
    <section
      className={st.core}
      style={noPadding ? { paddingBottom: 0 } : null}
    >
      <div className={st.container}>
        {items.map(({ image }, index) => (
          <Lazyload
            src={image}
            className={cn(st.img, { [st.active]: index === active })}
            key={index}
          />
        ))}

        <div className={st.text}>
          <span className={st1.pretitle}>A Votis acredita em</span>
          <div className={st.selector}>
            {items.map(({ name }, index) => (
              <h3
                key={index}
                className={index === active ? st.active : null}
                onClick={() => setActive(index)}
              >
                {name}
              </h3>
            ))}
          </div>
          <div>
            {items.map(({ description }, index) => (
              <p
                key={index}
                className={index === active ? st.active : null}
                onClick={() => setActive(index)}
              >
                {description}
              </p>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
