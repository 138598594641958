import React, { useState } from 'react'
import { Image as Item } from '~/components/Lightbox'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Slider from '@fil1pe/react-slider'
import { ArrowType } from '@fil1pe/react-slider'
import { Lazyload, Lightbox, Modal } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/Gallery.module.styl'

function getVideoId(url: string) {
  const urlParts = url.split(/(youtu\.be\/)|(youtube\.com\/watch\?v=)/)
  return urlParts[urlParts.length - 1]
}

type Props =
  | {
      type?: undefined
      items: Array<Item>
    }
  | {
      type: 'videos'
      items: Array<{ trumbnail?: string; url: string; legend?: string }>
    }

const Gallery = ({ items, type }: Props) => {
  const [images, setImages] = useState<Array<Item>>()
  const [index, setIndex] = useState<number>(0)
  const [open, setOpen] = useState(false)
  const onClick = (index: number) => {
    setIndex(index)
    if (type === 'videos') setOpen(true)
    else setImages(items)
  }

  const breakpoints = useBreakpoint()
  const mobile = !breakpoints.sm

  return (
    <>
      <Slider
        slidesToShow={mobile ? 1 : 3}
        renderArrow={({ className, ...props }, type) => (
          <button
            {...props}
            className={cn(className, type === ArrowType.Next ? 'next' : 'prev')}
          ></button>
        )}
        className={cn(st.core, 'slider')}
      >
        {items.map(({ thumbnail, url, legend }, index) => (
          <Lazyload
            src={
              type === 'videos'
                ? thumbnail ||
                  `https://img.youtube.com/vi/${getVideoId(url)}/0.jpg`
                : url
            }
            key={index}
            className={cn(st.slide, type === 'videos' && st.video)}
            onClick={() => onClick(index)}
          >
            {legend && <span>{legend}</span>}
          </Lazyload>
        ))}
      </Slider>

      <Lightbox
        images={images}
        index={index}
        setImages={setImages}
        setIndex={setIndex}
      />

      <Modal open={open} onClose={() => setOpen(false)}>
        {(closeProps) => (
          <div className={st.modal}>
            <button {...closeProps}></button>
            <iframe
              src={`https://www.youtube.com/embed/${getVideoId(
                items[index].url
              )}?enablejsapi=1`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        )}
      </Modal>
    </>
  )
}

export default Gallery
