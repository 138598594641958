export default [
  {
    name: 'Sobre',
    url: '/sobre/',
  },
  {
    name: 'Empreendimentos',
    url: '/empreendimentos/',
  },
  {
    name: 'Vendas',
    url: '/vendas/',
    menuBar: true,
  },
  {
    name: 'Contato',
    url: '/contato/',
  },
]
