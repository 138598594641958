import React, { useState, useEffect } from 'react'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import { Link, Logo } from '~/components'
import { navItems } from '~/data'
import cn from 'classnames'
import * as st from '~/assets/styl/MenuBar.module.styl'

interface Props {
  location: Location
}

const MenuBar = ({ location: { pathname } }: Props) => {
  const {
    configuration: config,
  }: {
    configuration: {
      instagram: string
      facebook: string
      linkedin: string
      youtube: string
      whatsapp: string
    }
  } = useApi(
    {
      configuration: useStaticQuery(graphql`
        query {
          allConfigurations {
            nodes {
              instagram
              facebook
              linkedin
              youtube
              whatsapp
            }
          }
        }
      `).allConfigurations.nodes[0],
    },
    'configuration',
    true
  )

  const [transparent, setTransparent] = useState(true)
  useEffect(() => {
    const onScroll = () => {
      setTransparent(!window.scrollY)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  return (
    <div
      className={cn(
        st.core,
        hamburgerOpen && st.open,
        transparent &&
          (pathname === '/' || pathname.startsWith('/empreendimento/')) &&
          st.transparent
      )}
      id="menu-bar"
    >
      <div className={st.container}>
        <Link href="/" onClick={() => setHamburgerOpen(false)}>
          <Logo />
        </Link>
        <div className={st.links}>
          <nav
            className={st.nav}
            itemScope
            itemType="http://schema.org/SiteNavigationElement"
          >
            <ul>
              {navItems.map(({ name, url }, key) => (
                <li key={key}>
                  <Link href={url} onClick={() => setHamburgerOpen(false)}>
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          {config.whatsapp && (
            <a
              href={`https://api.whatsapp.com/send?phone=55${config.whatsapp.replace(
                /\D/g,
                ''
              )}`}
              target="_blank"
              rel="noreferrer"
              className={st.whatsapp}
            >
              <span className="icon-whatsapp"></span> WhatsApp
            </a>
          )}
          <ul className={st.socials}>
            {config.instagram && (
              <li>
                <Link
                  href={config.instagram}
                  external
                  className="icon-instagram"
                ></Link>
              </li>
            )}
            {config.facebook && (
              <li>
                <Link
                  href={config.facebook}
                  external
                  className="icon-facebook"
                ></Link>
              </li>
            )}
            {config.linkedin && (
              <li>
                <Link
                  href={config.linkedin}
                  external
                  className="icon-linkedin"
                ></Link>
              </li>
            )}
            {config.youtube && (
              <li>
                <Link
                  href={config.youtube}
                  external
                  className="icon-youtube"
                ></Link>
              </li>
            )}
          </ul>
        </div>
        <button
          className={st.hamburger}
          onClick={() => setHamburgerOpen(!hamburgerOpen)}
        >
          <span></span>
        </button>
      </div>
    </div>
  )
}

export default MenuBar
