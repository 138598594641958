import React, { Dispatch, SetStateAction } from 'react'
import ReactLightbox from 'lightbox-react'
import 'lightbox-react/style.css'

export interface Image {
  url: string
  zoom?: string
  legend?: string
}

function zoomUrl(image: Image) {
  return image.zoom || image.url
}

interface Props {
  index: number
  images?: Array<Image>
  setIndex: Dispatch<SetStateAction<number>>
  setImages: Dispatch<SetStateAction<Array<Image>>>
}

const Lightbox = ({ index, images, setIndex, setImages }: Props) => (
  <>
    {images && (
      <ReactLightbox
        mainSrc={zoomUrl(images[index])}
        onCloseRequest={() => setImages(null)}
        prevSrc={zoomUrl(images[(index + images.length - 1) % images.length])}
        nextSrc={zoomUrl(images[(index + 1) % images.length])}
        onMovePrevRequest={() =>
          setIndex((index + images.length - 1) % images.length)
        }
        onMoveNextRequest={() => setIndex((index + 1) % images.length)}
        imageCaption={images[index].legend}
      />
    )}
  </>
)

export default Lightbox
