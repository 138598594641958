import React from 'react'
import { Button, Lazyload } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/HomeAbout.module.styl'
import * as st1 from '~/assets/styl/Hero.module.styl'
import img1 from '~/assets/img/Nosso-proposito.jpg'
import img2 from '~/assets/img/Espacos-planejados.jpg'

const HomeAbout = () => (
  <section className={st.core}>
    <div className={st.core}>
      <div className={st.container}>
        <div className={cn(st.heading, st.heading1)}>
          <div>
            <span className={cn(st1.pretitle, st.pretitle)}>
              Votis Empreendimentos
            </span>
            <h2>Nosso propósito é a satisfação de cada cliente.</h2>
          </div>
          <div>
            <p>
              Projetos pensados no bem-estar e conforto, entregando qualidade,
              acabamento diferenciado e pontualidade.
            </p>
          </div>
        </div>
        <div className={st.imageWrapper}>
          <Lazyload src={img1} className={st.image} />
        </div>
      </div>
    </div>
    <div className={cn(st.core, st.core2)}>
      <div className={st.container}>
        <div className={cn(st.heading, st.heading2)}>
          <div>
            <span className={cn(st1.pretitle, st.pretitle)}>
              Votis Empreendimentos
            </span>
            <h2>Espaços planejados para construir momentos</h2>
          </div>
          <div>
            <p>
              Escolha um empreendimento projetado para proporcionar grandes
              momentos desde a localização.
            </p>
            <Button href="/empreendimentos/">Conheça os empreendimentos</Button>
          </div>
        </div>
        <div className={st.imageWrapper}>
          <Lazyload src={img2} className={st.image} />
        </div>
      </div>
    </div>
  </section>
)

export default HomeAbout
