import React, { useState } from 'react'
import Slider, { ArrowType } from '@fil1pe/react-slider'
import { Lazyload, Lightbox, Button } from '~/components'
import { scroller } from 'react-scroll'
import { Image as Item } from '~/components/Lightbox'
import { roofImages as items } from '~/data'
import cn from 'classnames'
import * as st from '~/assets/styl/Roof.module.styl'
import * as st1 from '~/assets/styl/EnterpriseSlider.module.styl'
import * as st2 from '~/assets/styl/Gallery.module.styl'

export default function Roof() {
  const [images, setImages] = useState<Array<Item>>()
  const [index, setIndex] = useState<number>(0)
  const onClick = (index: number) => {
    setIndex(index)
    setImages(items)
  }

  return (
    <>
      <section className={cn(st1.core, st.core)}>
        <div className={st1.container}>
          <h2>Cobertura</h2>
          <div className={st.container}>
            <Slider
              slidesToShow={1}
              renderArrow={({ className, ...props }, type) => (
                <button
                  {...props}
                  className={cn(
                    className,
                    type === ArrowType.Next ? 'next' : 'prev'
                  )}
                ></button>
              )}
              className={cn(st.slider, 'slider')}
            >
              {items.map(({ url, legend }, index) => (
                <Lazyload
                  src={url}
                  key={index}
                  className={cn(st2.slide, st.slide)}
                  onClick={() => onClick(index)}
                >
                  {legend && <span>{legend}</span>}
                </Lazyload>
              ))}
            </Slider>
            <div className={st.text}>
              <h3>Uma mansão suspensa com vista para o mar</h3>
              <p>
                Uma das maiores coberturas da região com visão 180º para a
                Beira-mar de São José.
              </p>
              <ul>
                <li>
                  <span className="icon-bed"></span>3 suítes
                </li>
                <li>
                  <span className="icon-area"></span>340 m² de área privativa
                </li>
              </ul>
              <Button
                onClick={() =>
                  scroller.scrollTo('SoliciteInformacoes', {
                    smooth: true,
                    duration: 500,
                    offset:
                      -0.65 * document.getElementById('menu-bar').offsetHeight,
                  })
                }
              >
                Solicite informações
              </Button>
            </div>
          </div>
        </div>
      </section>

      <Lightbox
        images={images}
        index={index}
        setImages={setImages}
        setIndex={setIndex}
      />
    </>
  )
}
