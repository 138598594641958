import { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import api from '.'

const useApi = (defaultValue, path, save) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (path === 'configuration' && !save) {
      const f = setInterval(() => {
        if (window[`_${path}`]) {
          setValue(window[`_${path}`])
          clearInterval(f)
        }
      }, 100)
    } else
      api('get', path)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(({ code, message, ...data }) => {
          setValue(data)
          if (save) window[`_${path}`] = data
        })
        .catch(() => navigate('/404'))
  }, [])

  return value
}

export default useApi
