import React from 'react'

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 118.787">
    <defs>
      <clipPath id="clip-path">
        <rect width="120" height="118.787" fill="#fff" />
      </clipPath>
    </defs>
    <g transform="translate(0)">
      <path
        d="M1.138,0V28H8.866V7.728h20.27V0Z"
        transform="translate(-0.748 -0.001)"
        fill="#fff"
      />
      <path
        d="M286.214,264.8v20.27H265.945V292.8h28v-28Z"
        transform="translate(-174.764 -174.014)"
        fill="#fff"
      />
      <path
        d="M20.23,133.991,13.5,151.467l-.556,2.561-.556-2.561L5.663,133.991H0L10.49,161.25H15.4l10.491-27.258Z"
        transform="translate(0 -88.051)"
        fill="#fff"
      />
      <g transform="translate(0)">
        <g clipPath="url(#clip-path)">
          <path
            d="M109.506,153.458a24.972,24.972,0,0,0,.849-6.753,23.944,23.944,0,0,0-1.109-7.9,9.047,9.047,0,0,0-3.768-4.815,13.52,13.52,0,0,0-7.234-1.672q-5.074,0-7.741,2.084a9.654,9.654,0,0,0-3.521,5.087,24.6,24.6,0,0,0-.856,6.746,24.185,24.185,0,0,0,1.109,7.912,9.035,9.035,0,0,0,3.775,4.808,13.454,13.454,0,0,0,7.215,1.672q5.063,0,7.746-2.077a9.7,9.7,0,0,0,3.535-5.086m-6.664.215a5.251,5.251,0,0,1-4.6,2.337,5.141,5.141,0,0,1-4.694-2.394q-1.5-2.395-1.5-7.405,0-4.607,1.6-6.948a5.753,5.753,0,0,1,9.305.063q1.5,2.394,1.5,7.4,0,4.618-1.616,6.949"
            transform="translate(-56.597 -86.949)"
            fill="#fff"
          />
          <path
            d="M289.3,159.635a20.4,20.4,0,0,0,3,.811,18.3,18.3,0,0,0,3.712.355,17.19,17.19,0,0,0,6.233-1.013,8.661,8.661,0,0,0,3.991-2.933,7.534,7.534,0,0,0,1.374-4.5,6.943,6.943,0,0,0-1.248-4.345,7.4,7.4,0,0,0-2.851-2.325,34.421,34.421,0,0,0-4.174-1.463c-.374-.108-1.032-.279-1.982-.52a31.316,31.316,0,0,1-3.066-.868,4.518,4.518,0,0,1-1.7-1.019,2.335,2.335,0,0,1-.639-1.711,2.549,2.549,0,0,1,1.229-2.192,6.225,6.225,0,0,1,3.521-.836,18.461,18.461,0,0,1,3.852.5,35.806,35.806,0,0,1,4.9,1.495l.538-4.453a27.461,27.461,0,0,0-4.757-1.552,19.417,19.417,0,0,0-4.352-.563,15.978,15.978,0,0,0-5.581.867,8.11,8.11,0,0,0-3.585,2.458,7.021,7.021,0,0,0-1.5,3.68,10.411,10.411,0,0,0-.1,1.325,6.4,6.4,0,0,0,2.414,5.4,15,15,0,0,0,6.176,2.654c1.679.343,2.945.64,3.813.881a6.212,6.212,0,0,1,2.2,1.1,2.4,2.4,0,0,1,.912,1.976,2.2,2.2,0,0,1-1.318,2.034,8.654,8.654,0,0,1-3.015.905,21.4,21.4,0,0,1-3.148.171,34.553,34.553,0,0,1-8.115-2.477l-.538,4.611,1.033.437c1.013.438,1.938.8,2.768,1.108"
            transform="translate(-187.616 -87.071)"
            fill="#fff"
          />
          <path
            d="M173.692,133.97h-7.743v4.666h7.743v22.435h5.659V138.636h7.743V133.97h-13.4Z"
            transform="translate(-109.052 -88.037)"
            fill="#fff"
          />
          <rect
            width="5.659"
            height="27.101"
            transform="translate(85.19 45.933)"
            fill="#fff"
          />
          <path
            d="M272.181,242.742a1.466,1.466,0,0,1-.8.2,1.5,1.5,0,0,1-.886-.226,1.183,1.183,0,0,1-.419-.767H268.9a1.984,1.984,0,0,0,.722,1.493,2.814,2.814,0,0,0,1.755.492,2.62,2.62,0,0,0,1.755-.533,1.662,1.662,0,0,0,.583-1.329,1.343,1.343,0,0,0-.337-.968,2.634,2.634,0,0,0-.886-.526l-1.879-.714c-.273-.109-.41-.262-.41-.459a.582.582,0,0,1,.288-.493,1.264,1.264,0,0,1,.73-.2,1.184,1.184,0,0,1,.771.218,1.166,1.166,0,0,1,.352.718h1.2a1.731,1.731,0,0,0-.616-1.427,2.641,2.641,0,0,0-1.714-.5,2.433,2.433,0,0,0-1.6.5,1.62,1.62,0,0,0-.6,1.316,1.415,1.415,0,0,0,.311.944,2.062,2.062,0,0,0,.844.541l1.822.7c.333.131.5.3.5.516a.585.585,0,0,1-.311.509"
            transform="translate(-176.705 -156.217)"
            fill="#fff"
          />
          <path
            d="M230.8,239.154h1.8v4.9h1.23v-4.9H235.6v-1.075h-4.8Z"
            transform="translate(-151.67 -156.452)"
            fill="#fff"
          />
          <path
            d="M215.819,242.247l-2.436-4.167h-1.222v5.972h1.165v-4.167l2.493,4.167h1.165V238.08h-1.165Z"
            transform="translate(-139.42 -156.453)"
            fill="#fff"
          />
          <path
            d="M194.892,241.2H197.7v-1.009h-2.806v-1.033h3.076V238.08h-4.306v5.972H198.1v-1.075h-3.207Z"
            transform="translate(-127.263 -156.452)"
            fill="#fff"
          />
          <path
            d="M174.063,242.639l-.982-4.56h-1.889v5.972h1.165v-4.93l1.107,4.93h1.189l1.108-4.922v4.922h1.164v-5.972h-1.853Z"
            transform="translate(-112.498 -156.452)"
            fill="#fff"
          />
          <rect
            width="1.231"
            height="5.972"
            transform="translate(55.518 81.627)"
            fill="#fff"
          />
          <path
            d="M126.029,242.247l-2.436-4.167h-1.222v5.972h1.164v-4.167l2.494,4.167h1.165V238.08h-1.165Z"
            transform="translate(-80.416 -156.453)"
            fill="#fff"
          />
          <path
            d="M105.1,241.2h2.8v-1.009h-2.8v-1.033h3.076V238.08h-4.306v5.972h4.437v-1.075H105.1Z"
            transform="translate(-68.259 -156.452)"
            fill="#fff"
          />
          <path
            d="M86.6,241.2H89.41v-1.009H86.6v-1.033H89.68V238.08H85.374v5.972h4.437v-1.075H86.6Z"
            transform="translate(-56.103 -156.452)"
            fill="#fff"
          />
          <path
            d="M27.749,242.639l-.982-4.56H24.878v5.972h1.165v-4.93l1.107,4.93h1.189l1.108-4.922v4.922h1.164v-5.972H28.758Z"
            transform="translate(-16.349 -156.452)"
            fill="#fff"
          />
          <path
            d="M7.609,241.2h2.806v-1.009H7.609v-1.033h3.076V238.08H6.379v5.972h4.438v-1.075H7.609Z"
            transform="translate(-4.192 -156.452)"
            fill="#fff"
          />
          <path
            d="M250.624,237.723a2.694,2.694,0,0,0-2.091.861,3.232,3.232,0,0,0-.788,2.272,3.138,3.138,0,0,0,.791,2.227,2.721,2.721,0,0,0,2.088.849,2.62,2.62,0,0,0,2.051-.894,3.324,3.324,0,0,0,.8-2.3,3.052,3.052,0,0,0-.787-2.186,2.706,2.706,0,0,0-2.06-.832m1.141,4.568a1.4,1.4,0,0,1-1.174.566,1.353,1.353,0,0,1-1.238-.672,2.719,2.719,0,0,1-.337-1.435,2.177,2.177,0,0,1,.431-1.431,1.566,1.566,0,0,1,2.333,0,2.25,2.25,0,0,1,.418,1.448,2.445,2.445,0,0,1-.434,1.525"
            transform="translate(-162.804 -156.218)"
            fill="#fff"
          />
          <path
            d="M144.5,238.08h-2.322v5.972h2.232a2.629,2.629,0,0,0,2.207-.892,3.5,3.5,0,0,0,.607-2.2,3.174,3.174,0,0,0-.558-1.95,2.528,2.528,0,0,0-2.165-.934m1.2,4.4a1.479,1.479,0,0,1-1.321.5h-.968v-3.823h.968a1.483,1.483,0,0,1,1.255.475,2.313,2.313,0,0,1,.32,1.375,2.956,2.956,0,0,1-.254,1.474"
            transform="translate(-93.435 -156.453)"
            fill="#fff"
          />
          <path
            d="M70.242,241.263a1.074,1.074,0,0,0-.467-.328,2.077,2.077,0,0,0,.656-.507,1.271,1.271,0,0,0,.2-.76,1.4,1.4,0,0,0-.484-1.146,2.59,2.59,0,0,0-1.665-.442H65.846v5.972h1.23v-2.608h1.132a.929.929,0,0,1,.877.369,2.112,2.112,0,0,1,.14.936v.361a3.153,3.153,0,0,0,.029.52,3.062,3.062,0,0,0,.127.423h1.363q-.255-.238-.263-1.361a2.8,2.8,0,0,0-.238-1.428m-1.107-.942a2.242,2.242,0,0,1-.886.114H67.076v-1.28h1.173a2.4,2.4,0,0,1,.893.106q.214.106.214.522c0,.283-.073.463-.221.538"
            transform="translate(-43.27 -156.453)"
            fill="#fff"
          />
          <path
            d="M49.809,238.079H47.348v5.972h1.23v-2.371h1.189a2.34,2.34,0,0,0,1.584-.456,1.74,1.74,0,0,0,.516-1.373,1.677,1.677,0,0,0-.5-1.33,2.315,2.315,0,0,0-1.558-.442m.533,2.4a1.711,1.711,0,0,1-.8.13h-.96v-1.452h.976a1.552,1.552,0,0,1,.808.155.648.648,0,0,1,.234.584.624.624,0,0,1-.254.584"
            transform="translate(-31.114 -156.452)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default Logo
