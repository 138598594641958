import React from 'react'
import cn from 'classnames'
import * as st from '~/assets/styl/Loading.module.styl'

interface Props {
  active: boolean
  absolute?: boolean
}

const Loading = ({ active, absolute }: Props) => {
  return (
    <div
      className={cn(st.core, absolute && st.absolute)}
      style={!active ? { opacity: 0 } : null}
    >
      <div className={st.spinner}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0"
            y1="22"
            x2="44"
            y2="22"
          >
            <stop offset="0%" stopColor="#D8A8FF" />
            <stop offset="66%" stopColor="#86AEF6" />
            <stop offset="100%" stopColor="#D8A8FF" />
          </linearGradient>
          <circle
            cx="22"
            cy="22"
            r="20.2"
            fill="none"
            stroke="url(#linear-gradient)"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
        </svg>
      </div>
    </div>
  )
}

export default Loading
