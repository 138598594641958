import React from 'react'
import { navigate } from 'gatsby'
import { Link } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/Breadcrumb.module.styl'

const Breadcrumb = () => {
  return (
    <div className={cn(st.core, 'breadcrumb')}>
      <div>
        <Link href="/">Ir para a home</Link>
        <button onClick={() => navigate(-1)}>
          <span className="icon-back"></span>
          Voltar
        </button>
      </div>
    </div>
  )
}

export default Breadcrumb
