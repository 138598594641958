import React from 'react'
import { Button } from '~/components'
import nl2br from 'react-nl2br'
import cn from 'classnames'
import * as st from '~/assets/styl/Numbers.module.styl'

interface Props {
  items: Array<{
    id: number
    name: string
    description: string
  }>
  button?: boolean
  className?: string
}

const Numbers = ({ items, button = true, className }: Props) => {
  return (
    <section className={cn(st.core, className)}>
      <div className={st.container}>
        <div className={st.heading}>
          <h2>Nossos Números</h2>
          <p>
            Cada metro quadrado é projetado para transformar em realidade sonhos
            e desejos de nossos clientes.
          </p>
          {button && <Button href="/sobre/">Saiba mais</Button>}
        </div>
        <ul>
          {items.map(({ id, description: value, name }) => (
            <li key={id}>
              <h3>{value.replace(/^\+/, '')}</h3>
              <p>{nl2br(name)}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Numbers
