import { Image } from '~/components/Lightbox'

import thumb2 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_02.jpg'
import img2 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_02.jpg'
import thumb3 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_03.jpg'
import img3 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_03.jpg'
import thumb4 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_04.jpg'
import img4 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_04.jpg'
import thumb5 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_05.jpg'
import img5 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_05.jpg'
import thumb6 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_06.jpg'
import img6 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_06.jpg'
import thumb7 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_07.jpg'
import img7 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_07.jpg'
import thumb8 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_08.jpg'
import img8 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_08.jpg'
import thumb9 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_09.jpg'
import img9 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_09.jpg'
import thumb10 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_10.jpg'
import img10 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_10.jpg'
import thumb11 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_11.jpg'
import img11 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_11.jpg'
import thumb12 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_12.jpg'
import img12 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_12.jpg'
import thumb13 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_13.jpg'
import img13 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_13.jpg'
import thumb14 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_14.jpg'
import img14 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_14.jpg'
import thumb15 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_15.jpg'
import img15 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_15.jpg'
import thumb16 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_16.jpg'
import img16 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_16.jpg'
import thumb17 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_17.jpg'
import img17 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_17.jpg'
import thumb19 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_19.jpg'
import img19 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_19.jpg'
import thumb20 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_20.jpg'
import img20 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_20.jpg'
import thumb21 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_21.jpg'
import img21 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_21.jpg'
import thumb22 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_22.jpg'
import img22 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_22.jpg'
import thumb23 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_23.jpg'
import img23 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_23.jpg'
import thumb24 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_24.jpg'
import img24 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_24.jpg'
import thumb25 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_25.jpg'
import img25 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_25.jpg'
import thumb26 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_26.jpg'
import img26 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_26.jpg'
import thumb27 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_27.jpg'
import img27 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_27.jpg'
import thumb28 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_28.jpg'
import img28 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_28.jpg'
import thumb29 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_29.jpg'
import img29 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_29.jpg'
import thumb30 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_30.jpg'
import img30 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_30.jpg'
import thumb31 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_31.jpg'
import img31 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_31.jpg'
import thumb32 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_32.jpg'
import img32 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_32.jpg'
import thumb33 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_33.jpg'
import img33 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_33.jpg'
import thumb34 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_34.jpg'
import img34 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_34.jpg'
import thumb35 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_35.jpg'
import img35 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_35.jpg'
import thumb36 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_36.jpg'
import img36 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_36.jpg'
import thumb37 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_37.jpg'
import img37 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_37.jpg'
import thumb38 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_38.jpg'
import img38 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_38.jpg'
import thumb39 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_39.jpg'
import img39 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_39.jpg'
import thumb40 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_40.jpg'
import img40 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_40.jpg'
import thumb41 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_41.jpg'
import img41 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_41.jpg'
import thumb42 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_42.jpg'
import img42 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_42.jpg'
import thumb43 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_43.jpg'
import img43 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_43.jpg'
import thumb44 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_44.jpg'
import img44 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_44.jpg'
import thumb45 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_45.jpg'
import img45 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_45.jpg'
import thumb46 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_46.jpg'
import img46 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_46.jpg'
import thumb47 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_47.jpg'
import img47 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_47.jpg'
import thumb48 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_48.jpg'
import img48 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_48.jpg'
import thumb49 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_49.jpg'
import img49 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_49.jpg'
import thumb50 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_50.jpg'
import img50 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_50.jpg'
import thumb51 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_51.jpg'
import img51 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_51.jpg'
import thumb52 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_52.jpg'
import img52 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_52.jpg'
import thumb53 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_53.jpg'
import img53 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_53.jpg'
import thumb54 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_54.jpg'
import img54 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_54.jpg'
import thumb55 from '~/assets/img/cobertura/miniaturas/BZ_COBERTURADC_EP_R00-1_Pgina_55.jpg'
import img55 from '~/assets/img/cobertura/BZ_COBERTURADC_EP_R00-1_Pgina_55.jpg'

const data: Array<Image> = [
  { url: thumb2, zoom: img2 },
  { url: thumb3, zoom: img3 },
  { url: thumb4, zoom: img4 },
  { url: thumb5, zoom: img5 },
  { url: thumb6, zoom: img6 },
  { url: thumb7, zoom: img7 },
  { url: thumb8, zoom: img8 },
  { url: thumb9, zoom: img9 },
  { url: thumb10, zoom: img10 },
  { url: thumb11, zoom: img11 },
  { url: thumb12, zoom: img12 },
  { url: thumb13, zoom: img13 },
  { url: thumb14, zoom: img14 },
  { url: thumb15, zoom: img15 },
  { url: thumb16, zoom: img16 },
  { url: thumb17, zoom: img17 },
  { url: thumb19, zoom: img19 },
  { url: thumb20, zoom: img20 },
  { url: thumb21, zoom: img21 },
  { url: thumb22, zoom: img22 },
  { url: thumb23, zoom: img23 },
  { url: thumb24, zoom: img24 },
  { url: thumb25, zoom: img25 },
  { url: thumb26, zoom: img26 },
  { url: thumb27, zoom: img27 },
  { url: thumb28, zoom: img28 },
  { url: thumb29, zoom: img29 },
  { url: thumb30, zoom: img30 },
  { url: thumb31, zoom: img31 },
  { url: thumb32, zoom: img32 },
  { url: thumb33, zoom: img33 },
  { url: thumb34, zoom: img34 },
  { url: thumb35, zoom: img35 },
  { url: thumb36, zoom: img36 },
  { url: thumb37, zoom: img37 },
  { url: thumb38, zoom: img38 },
  { url: thumb39, zoom: img39 },
  { url: thumb40, zoom: img40 },
  { url: thumb41, zoom: img41 },
  { url: thumb42, zoom: img42 },
  { url: thumb43, zoom: img43 },
  { url: thumb44, zoom: img44 },
  { url: thumb45, zoom: img45 },
  { url: thumb46, zoom: img46 },
  { url: thumb47, zoom: img47 },
  { url: thumb48, zoom: img48 },
  { url: thumb49, zoom: img49 },
  { url: thumb50, zoom: img50 },
  { url: thumb51, zoom: img51 },
  { url: thumb52, zoom: img52 },
  { url: thumb53, zoom: img53 },
  { url: thumb54, zoom: img54 },
  { url: thumb55, zoom: img55 },
]

export default data
