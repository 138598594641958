// extracted by mini-css-extract-plugin
export var bottom = "Footer-module--bottom--5ee58";
export var container = "Footer-module--container--d66d8";
export var copy = "Footer-module--copy--0ea37";
export var core = "Footer-module--core--340e5";
export var location = "Footer-module--location--a1f16";
export var nav = "Footer-module--nav--7d35c";
export var phones = "Footer-module--phones--3245f";
export var socials = "Footer-module--socials--a5672";
export var tiki = "Footer-module--tiki--bc2cd";
export var top = "Footer-module--top--07a49";