import React from 'react'
import styled, { css } from 'styled-components'
import * as st from '~/assets/styl/Hero.module.styl'

const Image = styled.div<{ src: string }>`
  padding-top: 22.45%;
  ${({ src }) =>
    src &&
    css`
      background: url(${src}) center / cover;
    `}
`

interface Props {
  title: string
  image: string
}

const Hero = ({ title, image }: Props) => (
  <header className={st.core}>
    <div className={st.text}>
      <h1>{title}</h1>
    </div>
    <Image src={image} className={st.image} />
  </header>
)

export default Hero
