import React, { useState } from 'react'
import { Accordion, Lazyload, Lightbox } from '~/components'
import { Image } from '~/components/Lightbox'
import cn from 'classnames'
import * as st from '~/assets/styl/Plants.module.styl'
import * as st1 from '~/assets/styl/EnterpriseSlider.module.styl'

interface Context {
  title: string
  items: Array<{
    id: number
    name: string
    images: Array<{
      legend: string
      urls: {
        original: string
        large: string
      }
    }>
  }>
}

const Option = ({
  className,
  onClick,
  children,
}: {
  className?: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  children: string
}) => {
  const [hover, setHover] = useState(false)

  return (
    <li className={cn(className, hover && st.hover)}>
      <button
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onClick}
      >
        {children}
      </button>
    </li>
  )
}

const Plants = ({ title, items }: Context) => {
  const allPlants = [].concat(...items.map(({ images }) => images)) as Array<{
    legend: string
    urls: {
      original: string
      large: string
    }
  }>

  const [accordion, setAccordion] = useState(0)
  const [plant, setPlant] = useState(0)

  const [images, setImages] = useState<Array<Image>>()

  return items?.length ? (
    <>
      <section className={st1.core}>
        <div className={st.container}>
          <h2>{title || 'Plantas'}</h2>
          <div className={st.content}>
            <div>
              {items.map(({ id, name, images }, index) => (
                <Accordion
                  key={id}
                  title={name}
                  open={accordion === index}
                  onOpen={() => setAccordion(index)}
                >
                  <ul className={st.selector}>
                    {images.map((thisPlant, key) => (
                      <Option
                        key={key}
                        className={
                          plant === allPlants.indexOf(thisPlant) && st.active
                        }
                        onClick={() => setPlant(allPlants.indexOf(thisPlant))}
                      >
                        {thisPlant.legend}
                      </Option>
                    ))}
                  </ul>
                </Accordion>
              ))}
            </div>
            <div>
              <div className={st.plant}>
                {allPlants.map(({ urls: { large: image } }, index) => (
                  <Lazyload
                    key={index}
                    src={image}
                    contain
                    position="absolute"
                    className={plant === index ? st.active : null}
                    onClick={() =>
                      setImages(
                        allPlants.map(
                          ({ legend, urls: { original: url } }) => ({
                            url,
                            legend,
                          })
                        )
                      )
                    }
                  />
                ))}
              </div>
              <span>Clique na planta para ampliar</span>
            </div>
          </div>
        </div>
      </section>

      <Lightbox
        index={plant}
        images={images}
        setIndex={setPlant}
        setImages={setImages}
      />
    </>
  ) : (
    <></>
  )
}

export default Plants
