import React from 'react'
import Slider, { ArrowType } from '@fil1pe/react-slider'
import { Lazyload } from '~/components'
import nl2br from 'react-nl2br'
import cn from 'classnames'
import * as st from '~/assets/styl/Testimonials.module.styl'
import * as st1 from '~/assets/styl/EnterpriseSlider.module.styl'

interface Props {
  items: Array<{
    id: number
    name: string
    description: string
    image: string
  }>
}

const Testimonials = ({ items }: Props) => {
  return items?.length ? (
    <section className={cn(st1.core, st.core)}>
      <div className={st1.container}>
        <h2>Depoimentos</h2>
        <Slider
          className={cn('slider', st.slider)}
          renderArrow={({ className, ...props }, type) => (
            <button
              {...props}
              className={cn(
                className,
                type === ArrowType.Next ? 'next' : 'prev'
              )}
            ></button>
          )}
        >
          {items.map(({ name, description, image }, key) => (
            <div className={st.testimonial} key={key}>
              <div className={st.author}>
                <Lazyload
                  type="img"
                  src={image}
                  width="90"
                  height="90"
                  alt={name}
                />
                <p>{nl2br(name)}</p>
              </div>
              <p>{description}</p>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  ) : (
    <></>
  )
}

export default Testimonials
