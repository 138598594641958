import React, { useState } from 'react'
import Slider, { ArrowType } from '@fil1pe/react-slider'
import { Link, Lazyload, Button, Modal, Form } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/EnterpriseSlider.module.styl'
import * as st1 from '~/assets/styl/Hero.module.styl'
import * as st2 from '~/assets/styl/Sales.module.styl'

const EnterpriseLink = ({
  slug,
  name,
  children,
}: {
  slug: string
  name: string
  children: (open?: () => void) => React.ReactNode
}) => {
  const [open, setOpen] = useState(false)

  return !slug ? (
    <div className={cn(st.enterprise, st.release)}>
      {children(() => setOpen(true))}
      <Modal open={open} onClose={() => setOpen(false)}>
        {(closeProps) => (
          <div className={cn(st.modal, st2.form)}>
            <button {...closeProps} className={st.close}></button>
            <h3>
              Desejo receber mais informações sobre o futuro lançamento da Votis
              Construtora.
            </h3>
            <Form
              path="page-enterprise"
              idPrefix="release"
              button={<Button>Solicite mais informações</Button>}
              loadingButton={<Button>Enviando...</Button>}
              hiddenInputs={{ enterprise: name, subject: 'Futuro lançamento' }}
              inputs={[
                {
                  name: 'name',
                  label: 'Nome*',
                },
                {
                  name: 'phone',
                  label: 'WhatsApp*',
                  mask: 'phone',
                },
                {
                  name: 'email',
                  label: 'E-mail*',
                  type: 'email',
                },
                {
                  name: 'message',
                  label: 'Mensagem*',
                  type: 'textarea',
                },
                {
                  name: 'privacy',
                  label: (
                    <>
                      Declaro que li e aceito a{' '}
                      <Link href="/politica-de-privacidade/">
                        Política de Privacidade
                      </Link>
                    </>
                  ),
                  type: 'checkbox',
                },
              ]}
            />
          </div>
        )}
      </Modal>
    </div>
  ) : (
    <Link href={`/empreendimento/${slug}/`} className={st.enterprise}>
      {children()}
    </Link>
  )
}

const Slide = ({
  imageFeature: image,
  imageSideFirst,
  imageSideSecond,
  imageSideThird,
  name,
  neighborhood,
  city,
  state,
  slug,
  tag,
  room,
  release,
}: Enterprise & { release: boolean }) => {
  const location = [neighborhood, city, state].filter((i) => i).join(' - ')
  const photos = [imageSideFirst, imageSideSecond, imageSideThird].filter(
    (i) => i
  )

  return (
    <EnterpriseLink slug={release ? '' : slug} name={name}>
      {(open) => (
        <>
          <Lazyload
            src={image}
            className={cn(st.image, { [st.wide]: !photos.length })}
          />
          {Boolean(photos.length) && (
            <div className={st.images}>
              {photos.slice(0, 3).map((image, index) => (
                <Lazyload key={index} src={image} />
              ))}
            </div>
          )}
          <div className={st.text}>
            {tag && <span className={st.tag}>{tag}</span>}
            {location && (
              <span className={cn(st1.pretitle, st.location)}>{location}</span>
            )}
            <h3>{name}</h3>
            {room && (
              <p>
                <span className="icon-bed"></span>
                {room}
              </p>
            )}
            {open && <Button onClick={open}>Solicite mais informações</Button>}
          </div>
        </>
      )}
    </EnterpriseLink>
  )
}

interface Props {
  title?: string
  enterprises: Array<Enterprise>
  slider?: boolean
}

const EnterpriseSlider = ({
  title = 'Empreendimentos',
  enterprises,
  slider = true,
}: Props) => {
  return (
    <section className={st.core}>
      <div className={st.container}>
        <h2>{title}</h2>
        {slider ? (
          <Slider
            className={cn('slider', st.slider)}
            renderArrow={({ className, ...props }, type) => (
              <button
                {...props}
                className={cn(
                  className,
                  type === ArrowType.Next ? 'next' : 'prev'
                )}
              ></button>
            )}
            slidesToAppend={1}
            adaptiveHeight
          >
            {enterprises.map((enterprise, key) => (
              <Slide
                key={key}
                {...enterprise}
                release={title.toLowerCase().includes('lançamento')}
              />
            ))}
          </Slider>
        ) : (
          <ul className={st.listing}>
            {enterprises.map((enterprise, key) => (
              <li key={key}>
                <Slide
                  {...enterprise}
                  release={title.toLowerCase().includes('lançamento')}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  )
}

export default EnterpriseSlider
