import React from 'react'
import { Button } from '~/components'
import styled, { css } from 'styled-components'
import * as st from '~/assets/styl/HomeHero.module.styl'
import * as st1 from '~/assets/styl/Hero.module.styl'
import Slider from '@fil1pe/react-slider'

const Image = styled.div<{ src: string }>`
  ${({ src }) =>
    src &&
    css`
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 64%,
          rgba(0, 0, 0, 0) 86%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        url(${src}) center / cover;
    `}
`

interface Props {
  showcases: Array<{
    id: number
    location: string
    title: string
    subtitle: string
    cta: string
    url: string
    external: true
    image: string
    mobile: string
  }>
}

const HomeHero = ({ showcases }: Props) => {
  return (
    <header className={st.core}>
      <Slider
        renderArrow={() => <></>}
        autoplayTimeout={4000}
        className="slider"
      >
        {showcases.map(
          (
            {
              location,
              title,
              subtitle,
              cta: text_button,
              url: link_button,
              external,
              image,
            },
            index
          ) => (
            <Image src={image} className={st.banner} key={index}>
              <div className={st.container}>
                {location && <span className={st1.pretitle}>{location}</span>}
                <h3 className={st.title}>
                  {title}
                  {subtitle && (
                    <>
                      <br />
                      {subtitle}
                    </>
                  )}
                </h3>
                {Boolean(text_button && link_button) && (
                  <Button
                    href={link_button}
                    external={external}
                    domainPrefixed
                    className={st.btn}
                  >
                    {text_button}
                  </Button>
                )}
              </div>
            </Image>
          )
        )}
      </Slider>
    </header>
  )
}

export default HomeHero
