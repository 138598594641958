import React from 'react'
import { Button, Map } from '~/components'
import * as st from '~/assets/styl/Location.module.styl'
import * as st1 from '~/assets/styl/EnterpriseSlider.module.styl'

interface Props {
  location?: string
  children?: React.ReactNode
  latitude?: string
  longitude?: string
}

const Location = ({
  location = 'São José / SC',
  children = (
    <>
      R. José Aurino de Matos, 195
      <br />
      Kobrasol, São José - SC, 88102-450
    </>
  ),
  latitude = '-27.5995255',
  longitude = '-48.6192987',
}: Props) => (
  <section className={st1.core}>
    <div className={st1.container}>
      <h2>Localização</h2>
      <div className={st.box}>
        <div className={st.map}>
          <Map location={{ lat: latitude, lng: longitude }} />
        </div>
        <div className={st.text}>
          <h3>{location}</h3>
          <p>{children}</p>
          <Button
            href={`https://www.google.com/maps?saddr=My+Location&daddr=${latitude},${longitude}`}
            external
            className={st.btn}
          >
            Traçar uma rota
          </Button>
        </div>
      </div>
    </div>
  </section>
)

export default Location
