import React from 'react'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import { states } from '~/data'
import { Tabs, Form, Button, Link } from '~/components'
import * as st from '~/assets/styl/Contact.module.styl'

const ContactForms = ({ location: { pathname } }: { location: Location }) => {
  const {
    configuration: config,
  }: { configuration: { whatsapp: string; phone: string } } = useApi(
    {
      configuration: useStaticQuery(graphql`
        query {
          allConfigurations {
            nodes {
              whatsapp
              phone
            }
          }
        }
      `).allConfigurations.nodes[0],
    },
    'configuration'
  )
  const { subjects }: { subjects: Array<{ name: string; id: string }> } =
    useApi({ subjects: [] }, 'page-contact')
  const { specialties }: { specialties: Array<{ name: string; id: string }> } =
    useApi({ specialties: [] }, 'page-curriculum')

  const workWithUs = pathname.includes('trabalhe-conosco')

  return (
    <section id="Formulario">
      <Tabs
        defaultPage={workWithUs ? 1 : 0}
        pages={['Fale Conosco', 'Trabalhe Conosco']}
        classes={{ selector: st.tabs, content: st.container }}
      >
        <div>
          <p>
            Entre em contato com nosso time de consultores para obter mais
            informações.
          </p>
          <div className={st.divider}>
            <Form
              path="page-contact"
              idPrefix="contact"
              button={<Button>Enviar mensagem</Button>}
              loadingButton={<Button>Enviando...</Button>}
              inputs={[
                {
                  name: 'name',
                  label: 'Nome*',
                  className: 'small',
                },
                {
                  name: 'email',
                  label: 'E-mail*',
                  type: 'email',
                  className: 'small',
                },
                {
                  name: 'phone',
                  label: 'WhatsApp*',
                  mask: 'phone',
                  className: 'small',
                },
                {
                  name: 'subject',
                  label: 'Interesse do contato*',
                  type: 'select',
                  options: subjects.map(({ name }) => ({
                    name,
                  })),
                  placeholder: 'Selecione',
                  className: 'small',
                },
                {
                  name: 'message',
                  label: 'Mensagem*',
                  type: 'textarea',
                },
                {
                  name: 'privacy',
                  label: (
                    <>
                      Declaro que li e aceito a{' '}
                      <Link href="/politica-de-privacidade/">
                        Política de Privacidade
                      </Link>
                    </>
                  ),
                  type: 'checkbox',
                },
              ]}
            />
            <div>
              <h2>Entre em contato</h2>
              <p>Utilize nossos canais para entrar em contato.</p>
              <div className={st.phones}>
                {config.phone && (
                  <Link
                    href={`tel:${config.phone.replace(/\D/g, '')}`}
                    external
                  >
                    <span className="icon-phone"></span>
                    {config.phone}
                  </Link>
                )}
                {config.whatsapp && (
                  <Link
                    href={`https://api.whatsapp.com/send?phone=55${config.whatsapp.replace(
                      /\D/g,
                      ''
                    )}`}
                    external
                  >
                    <span className="icon-whatsapp"></span>
                    {config.whatsapp}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <p>
            Procurando uma oportunidade para trabalhar com a Votis? Deixe seus
            dados, venha fazer parte.
          </p>
          <Form
            path="page-curriculum"
            idPrefix="curriculum"
            className={st.workWithUs}
            button={<Button>Enviar mensagem</Button>}
            loadingButton={<Button>Enviando...</Button>}
            inputs={[
              {
                name: 'name',
                label: 'Nome*',
                className: 'small',
                group: 0,
              },
              {
                name: 'email',
                label: 'E-mail*',
                type: 'email',
                className: 'small',
                group: 0,
              },
              {
                name: 'state',
                label: 'Estado*',
                type: 'select',
                options: states.map((name) => ({
                  name,
                })),
                placeholder: 'Selecione',
                className: 'small',
                group: 0,
              },
              {
                name: 'city',
                label: 'Cidade*',
                className: 'small',
                group: 0,
              },
              {
                name: 'phone',
                label: 'WhatsApp*',
                mask: 'phone',
                className: 'small',
                group: 0,
              },
              {
                name: 'area',
                label: 'Área de interesse*',
                type: 'select',
                options: specialties.map(({ name }) => ({
                  name,
                })),
                placeholder: 'Selecione',
                className: 'small',
                group: 0,
              },
              {
                name: 'file',
                label: 'Currículo*',
                type: 'file',
                file: {
                  accept: 'application/msword, application/pdf',
                  button: (props) => (
                    <button {...props}>Anexar currículo</button>
                  ),
                },
                group: 1,
              },
              {
                name: 'message',
                label: 'Mensagem*',
                type: 'textarea',
                group: 1,
              },
              {
                name: 'privacy',
                label: (
                  <>
                    Declaro que li e aceito a{' '}
                    <Link href="/politica-de-privacidade/">
                      Política de Privacidade
                    </Link>
                  </>
                ),
                type: 'checkbox',
                group: 1,
              },
            ]}
          />
        </div>
      </Tabs>
    </section>
  )
}

export default ContactForms
